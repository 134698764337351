import React from 'react';

import { LayoutLoggedIn } from '../components/views/layout';
import Discounts from '../components/views/discounts'

export default function() {
    return (
        <LayoutLoggedIn>
            <Discounts />
        </LayoutLoggedIn>
    );
}